/// <reference path="o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts" />

/**********************************************************************/
/* ----        IMPORTANT! Read before making any changes         ---- */
/**********************************************************************/
/* ----           This file is part of a set of files            ---- */
/* ----            Any changes here MUST be added to:            ---- */
/**********************************************************************/
/* - o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts     */
/* - o365.pwa.modules.client.dexie.databases.O365PWACore.ts           */
/* - o365.pwa.modules.sw.dexie.databases.O365PWACore.ts               */
/**********************************************************************/

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import * as O365PWACoreModule from 'o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts';

import type { AppState } from 'o365.pwa.types.ts';
import type { IUserDeviceOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';
import type { IServiceWorkerStateOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';
import type { Table } from 'o365.pwa.declaration.sw.dexie.d.ts';
import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type { IDataObjectConfig } from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

/* ------------------------------------------------------------ //
    This file is mirrored

    ANY CHANGES MUST BE REPLICATED IN

    o365.pwa.modules.sw.dexie.databases.o365PWACore.ts
// ------------------------------------------------------------ */
(() => {
    const { App } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.App.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.App.ts");
    const { Database } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.Database2.ts");
    const { ObjectStore } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.ObjectStore.ts");
    const { PWAState } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.PWAState.ts");
    const { ServiceWorkerState } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.ServiceWorkerState.ts");
    const { ServiceWorkerScriptState } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.ServiceWorkerScriptState.ts");
    const { AppResourceState } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.AppResourceState.ts");
    const { Index } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.Index.ts");
    const { User } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.User.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.User.ts");
    const { GlobalSetting } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.GlobalSetting.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.GlobalSetting.ts");
    const { UserDevice } = self.o365.importScripts<typeof import("o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts")>("o365.pwa.modules.sw.dexie.objectStores.UserDevice.ts");

    class O365PWACore extends self.Dexie.latestVersion.Dexie implements O365PWACoreModule.O365PWACore {
        private apps!: Table<InstanceType<typeof App>, string>;
        private databases!: Table<InstanceType<typeof Database>, Array<string>>;
        private objectStores!: Table<InstanceType<typeof ObjectStore>, Array<string>>;
        private indexes!: Table<InstanceType<typeof Index>, Array<string>>;
        private users!: Table<InstanceType<typeof User>, number>;
        private globalSettings!: Table<InstanceType<typeof GlobalSetting>, number>;
        private pwaStates!: Table<InstanceType<typeof PWAState>, string>;
        private serviceWorkerStates!: Table<InstanceType<typeof ServiceWorkerState>, string>;
        private serviceWorkerScriptStates!: Table<InstanceType<typeof ServiceWorkerScriptState>, Array<string>>;
        private appResourceStates!: Table<InstanceType<typeof AppResourceState>, Array<string>>;
        private userDevice!: Table<InstanceType<typeof UserDevice>, string>;

        public constructor() {
            super('O365_PWA_CORE');

            super.version(1).stores({
                apps: App.objectStoreDexieSchema,
                databases: Database.objectStoreDexieSchema,
                indexes: Index.objectStoreDexieSchema,
                objectStores: ObjectStore.objectStoreDexieSchema,
                users: User.objectStoreDexieSchema,
                globalSettings: GlobalSetting.objectStoreDexieSchema,
                pwaStates: PWAState.objectStoreDexieSchema,
                serviceWorkerStates: ServiceWorkerState.objectStoreDexieSchema,
                serviceWorkerScriptStates: ServiceWorkerScriptState.objectStoreDexieSchema,
                appResourceStates: AppResourceState.objectStoreDexieSchema,
                userDevice: UserDevice.objectStoreDexieSchema
            });

            this.apps.mapToClass(App);
            this.databases.mapToClass(Database);
            this.objectStores.mapToClass(ObjectStore);
            this.indexes.mapToClass(Index);
            this.users.mapToClass(User);
            this.globalSettings.mapToClass(GlobalSetting);
            this.pwaStates.mapToClass(PWAState);
            this.serviceWorkerStates.mapToClass(ServiceWorkerState);
            this.serviceWorkerScriptStates.mapToClass(ServiceWorkerScriptState);
            this.appResourceStates.mapToClass(AppResourceState);
            this.userDevice.mapToClass(UserDevice);
        }

        /* -------------- */
        /* ---- apps ---- */
        /* -------------- */
        // region
        public async createApp(appId: string, title?: string, icon?: string): Promise<void> {
            const app = new App(appId, title, icon);

            await app.save();
        }

        public async getApps(): Promise<Array<InstanceType<typeof App>>> {
            return await this.apps.toArray();
        }

        public async getApp(appId: string): Promise<InstanceType<typeof App> | null> {
            const app = await this.apps.get(appId);

            return app ?? null;
        }

        public async updateApp(app: InstanceType<typeof App>): Promise<void> {
            await this.apps.put(JSON.parse(JSON.stringify(app)));
        }

        public async deleteApp(app: InstanceType<typeof App>): Promise<void> {
            await this.apps.delete(app.id);
        }
        // endregion

        /* ------------------- */
        /* ---- databases ---- */
        /* ------------------- */
        // region
        public async createDatabase(appId: string, databaseId: string): Promise<void> {
            const database = new Database(databaseId, appId);

            await database.save()
        }

        public async getDatabases(appId: string): Promise<Array<InstanceType<typeof Database>>> {
            return await this.databases
                .where('appId')
                .equals(appId)
                .toArray();
        }

        public async getDatabase(appId: string, databaseId: string): Promise<InstanceType<typeof Database> | null> {
            const database = await this.databases.get([appId, databaseId]);

            return database ?? null;
        }

        public async updateDatabase(database: InstanceType<typeof Database>): Promise<void> {
            await this.databases.put(JSON.parse(JSON.stringify(database)));
        }

        public async deleteDatabase(database: InstanceType<typeof Database>): Promise<void> {
            await this.databases.delete([database.appId, database.id]);
        }
        // endregion

        /* ---------------------- */
        /* ---- objectStores ---- */
        /* ---------------------- */
        // region
        public async createObjectStore(appId: string, databaseid: string, objectStoreId: string, jsonDataVersion: number | null, fields: Array<string> | null, isPropertyTable: boolean = false, dataObjectConfig: IDataObjectConfig | undefined = undefined, initializeDataObject: boolean = false): Promise<void> {
            const objectStore = new ObjectStore(objectStoreId, databaseid, appId, jsonDataVersion, fields, isPropertyTable, dataObjectConfig, initializeDataObject);

            await objectStore.save();
        }

        public async getObjectStores(appId: string, databaseId: string): Promise<Array<InstanceType<typeof ObjectStore>>> {
            return await this.objectStores
                .where(['appId', 'databaseId'])
                .equals([appId, databaseId])
                .toArray();
        }

        public async getObjectStore(appId: string, databaseId: string, objectStoreId: string): Promise<InstanceType<typeof ObjectStore> | null> {
            const objectStore = await this.objectStores.get([appId, databaseId, objectStoreId]);

            return objectStore ?? null;
        }

        public async updateObjectStore(objectStore: InstanceType<typeof ObjectStore>): Promise<void> {
            await this.objectStores.put(JSON.parse(JSON.stringify(objectStore)));
        }

        public async deleteObjectStore(objectStore: InstanceType<typeof ObjectStore>): Promise<void> {
            await this.objectStores.delete([objectStore.appId, objectStore.databaseId, objectStore.id]);
        }
        // endregion

        /* ----------------- */
        /* ---- indexes ---- */
        /* ----------------- */
        // region
        public async createIndex(appId: string, databaseId: string, objectStoreId: string, indexId: string, keyPath: string | string[] | null, isPrimaryKey?: boolean, isUnique?: boolean, isMultiEntry?: boolean, isAutoIncrement?: boolean): Promise<void> {
            const index = new Index(indexId, appId, databaseId, objectStoreId, keyPath, isPrimaryKey, isUnique, isMultiEntry, isAutoIncrement);

            await index.save();
        }

        public async getIndexes(appId: string, databaseId: string, objectStoreId: string): Promise<Array<InstanceType<typeof Index>>> {
            return await this.indexes
                .where(['appId', 'databaseId', 'objectStoreId'])
                .equals([appId, databaseId, objectStoreId])
                .toArray();
        }

        public async getIndex(appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<InstanceType<typeof Index> | null> {
            const index = await this.indexes.get([appId, databaseId, objectStoreId, indexId]);

            return index ?? null;
        }

        public async updateIndex(index: InstanceType<typeof Index>): Promise<void> {
            await this.indexes.put(JSON.parse(JSON.stringify(index)));
        }

        public async deleteIndex(index: InstanceType<typeof Index>): Promise<void> {
            await this.indexes.delete([index.appId, index.databaseId, index.objectStoreId, index.id]);
        }
        // endregion

        /* --------------- */
        /* ---- users ---- */
        /* --------------- */
        // region
        public async getUser(): Promise<InstanceType<typeof User> | null> {
            const users: Array<InstanceType<typeof User>> = await this.users.toArray();

            if (users.length === 0) {
                return null;
            } else if (users.length > 1) {
                self.o365.logger.warn('Multiple users found. Expected one.');
            }

            return users[0];
        }

        public async createUser(personId: number, userSession: any): Promise<void> {
            const user = new User(personId, userSession);

            await user.save();
        }

        public async updateUser(user: InstanceType<typeof User>): Promise<void> {
            await this.users.put(JSON.parse(JSON.stringify(user)));
        }

        public async deleteUser(user: InstanceType<typeof User>): Promise<void> {
            await this.users.delete(user.id);
        }
        // endregion

        /* ------------------------ */
        /* ---- globalSettings ---- */
        /* ------------------------ */
        // region
        public async getGlobalSetting(): Promise<InstanceType<typeof GlobalSetting> | null> {
            const globalSettings: Array<InstanceType<typeof GlobalSetting>> = await this.globalSettings.toArray();

            if (globalSettings.length === 0) {
                return null;
            } else if (globalSettings.length > 1) {
                self.o365.logger.warn('Multiple users found. Expected one.');
            }

            return globalSettings[0];
        }

        public async createGlobalSetting(cdnUrl: any): Promise<void> {
            const globalSetting = new GlobalSetting(cdnUrl);

            await globalSetting.save();
        }

        public async updateGlobalSetting(globalSetting: InstanceType<typeof GlobalSetting>): Promise<void> {
            await this.globalSettings.put(JSON.parse(JSON.stringify(globalSetting)));
        }

        public async deleteGlobalSetting(globalSetting: InstanceType<typeof GlobalSetting>): Promise<void> {
            await this.globalSettings.delete(globalSetting.id);
        }
        // endregion

        /* ------------------- */
        /* ---- pwaStates ---- */
        /* ------------------- */
        // region
        public async getPwaState(appId: string): Promise<InstanceType<typeof PWAState> | null> {
            const pwaState = await this.pwaStates.get(appId);

            return pwaState ?? null;
        }

        public async createPwaState(appId: string, appState: AppState = 'OFFLINE', hasDatabaseConnection: boolean) {
            const pwaState = new PWAState(appId, appState, hasDatabaseConnection);

            await pwaState.save();
        }

        public async updatePwaState(pwaState: InstanceType<typeof PWAState>): Promise<void> {
            await this.pwaStates.put(JSON.parse(JSON.stringify(pwaState)));
        }

        public async deletePwaState(pwaState: InstanceType<typeof PWAState>): Promise<void> {
            await this.pwaStates.delete(pwaState.appId);
        }
        // endregion

        /* ----------------------------- */
        /* ---- serviceWorkerStates ---- */
        /* ----------------------------- */
        // region
        public async getServiceWorkerState(appId: string): Promise<InstanceType<typeof ServiceWorkerState> | null> {
            const serviceWorkerState = await this.serviceWorkerStates.get(appId);

            return serviceWorkerState ?? null;
        }

        public async createServiceWorkerState(options: IServiceWorkerStateOptions): Promise<void> {
            const serviceWorkerState = new ServiceWorkerState(options);

            await serviceWorkerState.save();
        }

        public async updateServiceWorkerState(serviceWorkerState: InstanceType<typeof ServiceWorkerState>): Promise<void> {
            await this.serviceWorkerStates.put(JSON.parse(JSON.stringify(serviceWorkerState)));
        }

        public async deleteServiceWorkerState(serviceWorkerState: InstanceType<typeof ServiceWorkerState>): Promise<void> {
            await this.serviceWorkerStates.delete(serviceWorkerState.appId);
        }
        // endregion

        /* ----------------------------------- */
        /* ---- serviceWorkerScriptStates ---- */
        /* ----------------------------------- */
        // region
        public async getServiceWorkerScriptStates(appId: string): Promise<Array<InstanceType<typeof ServiceWorkerScriptState>>> {
            return await this.serviceWorkerScriptStates
                .where('appId')
                .equals(appId)
                .toArray();
        }

        public async getServiceWorkerScriptState(appId: string, serviceWorkerScriptStateId: string): Promise<InstanceType<typeof ServiceWorkerScriptState> | null> {
            const serviceWorkerScriptState = await this.serviceWorkerScriptStates.get([appId, serviceWorkerScriptStateId]);

            return serviceWorkerScriptState ?? null;
        }

        public async createServiceWorkerScriptState(appId: string, id: string, importmapEntry: IServiceWorkerImportMapEntry): Promise<void> {
            const serviceWorkerState = new ServiceWorkerScriptState({ appId, id, importmapEntry });

            await serviceWorkerState.save();
        }

        public async updateServiceWorkerScriptState(serviceWorkerScriptState: InstanceType<typeof ServiceWorkerScriptState>): Promise<void> {
            await this.serviceWorkerScriptStates.put(JSON.parse(JSON.stringify(serviceWorkerScriptState)));
        }

        public async deleteServiceWorkerScriptState(serviceWorkerScriptState: InstanceType<typeof ServiceWorkerScriptState>): Promise<void> {
            await this.serviceWorkerScriptStates.delete([serviceWorkerScriptState.appId, serviceWorkerScriptState.id]);
        }
        // endregion

        /* --------------------------- */
        /* ---- appResourceStates ---- */
        /* --------------------------- */
        // region
        public async getAppResourceStates(appId?: string): Promise<Array<InstanceType<typeof AppResourceState>>> {
            if (appId) {
                return await this.appResourceStates
                    .where('appId')
                    .equals(appId)
                    .toArray();
            }

            return await this.appResourceStates.toArray();
        }

        public async getAppResourceState(appId: string, appResourceStateId: string): Promise<InstanceType<typeof AppResourceState> | null> {
            const appResourceState = await this.appResourceStates.get([appId, appResourceStateId]);

            return appResourceState ?? null;
        }

        public async createAppResourceState(appId: string, id: string, relativeRoots: Array<string> = new Array(), urls: Array<string> = new Array(), scopes: Array<string> = new Array()): Promise<void> {
            const appResourceState = new AppResourceState(appId, id, relativeRoots, urls, scopes);

            await appResourceState.save();
        }

        public async updateAppResourceState(appResourceState: InstanceType<typeof AppResourceState>): Promise<void> {
            await this.appResourceStates.put(JSON.parse(JSON.stringify(appResourceState)));
        }

        public async deleteAppResourceState(appResourceState: InstanceType<typeof AppResourceState>): Promise<void> {
            await this.appResourceStates.delete([appResourceState.appId, appResourceState.id]);
        }
        // endregion

        /* -------------------- */
        /* ---- userDevice ---- */
        /* -------------------- */
        // region
        public async getUserDevice(): Promise<InstanceType<typeof UserDevice> | null> {
            const device: Array<InstanceType<typeof UserDevice>> = await this.userDevice.toArray();

            if (device.length === 0) {
                return null;
            } else if (device.length > 1) {
                self.o365.logger.warn('Multiple devices found. Expected one.');
            }

            return device[0];
        }

        public async createUserDevice(userDeviceOptions: IUserDeviceOptions): Promise<void> {
            const userDevice = new UserDevice(userDeviceOptions)
            await userDevice.save();
        }

        public async updateUserDevice(userDevice: InstanceType<typeof UserDevice>): Promise<void> {
            await this.userDevice.put(JSON.parse(JSON.stringify(userDevice)));
        }

        public async deleteUserDevice(userDevice: InstanceType<typeof UserDevice>): Promise<void> {
            await this.userDevice.delete(userDevice.deviceRef);
        }
        // endregion
    }

    let _instance: O365PWACore | null = null;

    self.o365.exportScripts<typeof import('o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts')>({
        O365PWACore,
        o365PWACore: {
            get instance() {
                _instance ??= new O365PWACore();

                return _instance;
            }
        },
        self
    });
})();